import 'bootstrap/js/dist/modal'
import 'jquery-validation'
import Paypal from 'paypal/paypal'
import AddToCartButton from 'shopping_cart/scripts/add_to_cart_button'
import ShoppingCartFloatingButton from 'shopping_cart/scripts/cart_floating_button'
import CheckoutSuccessListener from 'shopping_cart/scripts/checkout_success_listener'

$(function () {
  Paypal.init()
  AddToCartButton.init()
  ShoppingCartFloatingButton.init()
  CheckoutSuccessListener.init()
})
