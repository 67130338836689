import OrderItemsStore from './order_items_store'

class CheckoutSuccessListener {
  static async init () {
    return new CheckoutSuccessListener()
  }

  constructor () {
    this.maybeClearCart()
  }

  maybeClearCart () {
    let $el = $('#checkout-success')
    if ($el.length > 0) {
      OrderItemsStore.clear()
    }
  }
}

export default CheckoutSuccessListener
