import Currency from 'utils/currency'

class Paypal {
  static async init () {
    const clienId = $('[data-paypal-key]').data('paypal-key')
    const sellerId = $('[data-paypal-sid]').data('paypal-sid')
    const currency = Currency.getCurrency()

    if (clienId) {
      let url = `https://www.paypal.com/sdk/js?client-id=${clienId}&currency=${currency}&disable-funding=card,credit`
      if (sellerId === 2841) url = url + '&enable-funding=venmo'
      $.ajax({
        url: url,
        dataType: 'script',
        cache: true
      })
        .fail((error) => {
          console.log(error)
        })
    }
  }
}

export default Paypal
