/* global paypal */
/* global I18n */

import OrderItemsStore from './order_items_store'
import Track from 'tracking_events/scripts/track'
import WebsiteConfigs from 'website_configs/website_configs'
import Currency from 'utils/currency'
import ShoppingCart from './shopping_cart'
import DynamicModal from 'modals/scripts/dynamic_modal'
import validPaypalCurrencies from 'paypal/currencies'
import COUNTRIES from 'utils/countries'

const currency = Currency.getCurrency()
const SHOPPING_CART_MODAL_SELECTOR = '#shopping_cart_modal'
const HIDE_CURRENCY_ISO_CODE = WebsiteConfigs.hideCurrencyIsoCode
const DECIMAL_PLACES = currency === 'JPY' ? 0 : 2

class PaypalCheckoutForm {
  static render ($parent) {
    return new PaypalCheckoutForm($parent)
  }

  constructor ($parent) {
    this.$parent = $parent
    this.isLoading = true
    this.render()

    this.checkShippingEnabled().done((result) => {
      this.paypalShippingEnabled = result.shipping_enabled || result.tax_rate_eu_added
      this.isLoading = false

      if (!this.paypalShippingEnabled) {
        this.loadShippingAndTax().finally(() => {
          this.render()
        })
      } else {
        this.render()
      }
    }).catch((_error) => {
      this.isLoading = false
      this.isFailure = true
      this.render()
    })
  }

  render () {
    this.$parent.html(this.template).ready(() => {
      if (this.isLoading || this.isFailure) return

      this.$form = this.$parent.children().first()
      this.bindEvents()

      if ($('[data-paypal-key]').data('paypal-key') && OrderItemsStore.getValidItems().length > 0 && (!this.paypalShippingEnabled || this.shippingCalculated)) {
        this.renderPaypal()
      }
    })
  }

  bindEvents () {
    $('[data-role="shopping-cart-icon"]').on('click', (_e) => {
      this.$parent.closest('.modal').modal('hide')
    })

    this.$form.find('.shipping-calculation--back').on('click', (_e) => {
      if (this.paypalShippingEnabled) {
        this.shippingCalculated = false
        this.submittedTaxAndShippingCalculation = null
        this.render()
      } else {
        this.$parent.closest('.modal').modal('hide')
        const modal = new DynamicModal(SHOPPING_CART_MODAL_SELECTOR)
        ShoppingCart.render(modal.$body)
        modal.open()
      }
    })

    this.$form.find('.shopping-cart--back').on('click', (_e) => {
      this.$parent.closest('.modal').modal('hide')
      const modal = new DynamicModal(SHOPPING_CART_MODAL_SELECTOR)
      ShoppingCart.render(modal.$body)
      modal.open()
    })

    this.$form.validate({
      submitHandler: () => { this.onSubmit() },
      rules: {
        full_name: {
          required: true
        },
        address_line_1: {
          required: true
        },
        city: {
          required: true
        },
        region_state: {
          required: true
        },
        postal_code: {
          required: true
        },
        country_code: {
          required: true
        }
      },
      messages: {
        full_name: {
          required: I18n.t('validation.full_name')
        },
        address_line_1: {
          required: I18n.t('validation.address_line_1')
        },
        city: {
          required: I18n.t('validation.city')
        },
        region_state: {
          required: I18n.t('validation.region_state')
        },
        postal_code: {
          required: I18n.t('validation.postal_code')
        },
        country_code: {
          required: I18n.t('validation.country')
        }
      }
    })
  }

  onSubmit () {
    event.preventDefault()

    this.disabledSubmitButton()

    this.loadShippingAndTax().then((_result) => {
      this.shippingCalculated = true

      this.render()
    }).catch((error) => {
      if (error.status === 422) {
        const errorMessage = error.responseJSON.error_message

        this.$form.find('.error-message').html(errorMessage)
        this.$form.find('#error_alert').show()
      }
    }).finally(() => {
      this.enableSubmitButton()
    })
  }

  safeValue (value) {
    return value.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
  }

  checkShippingEnabled () {
    return $.ajax({
      url: '/api/paypal_check_shipping_enabled',
      method: 'GET',
      contentType: 'application/json'
    })
  }

  loadShippingAndTax () {
    return new Promise((resolve, reject) => {
      if (this.submittedTaxAndShippingCalculation) {
        return resolve(this.submittedTaxAndShippingCalculation)
      } else {
        $.ajax({
          url: '/api/paypal_shipping_and_tax',
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({ order: this.formData })
        }).done((result) => {
          if (this.paypalShippingEnabled) {
            result.shipping.fullName = this.safeValue(this.formData.full_name)
            result.shipping.addressLine1 = this.safeValue(this.formData.address_line_1)
            result.shipping.addressLine2 = this.safeValue(this.formData.address_line_2)
            result.shipping.adminArea2 = this.safeValue(this.formData.city)
            result.shipping.adminArea1 = this.safeValue(this.formData.region_state)
            result.shipping.postalCode = this.safeValue(this.formData.postal_code)
            result.shipping.countryCode = this.safeValue(this.formData.country_code)
            result.shipping.shippingAddress = [
              result.shipping.addressLine1,
              result.shipping.addressLine2,
              result.shipping.adminArea2,
              result.shipping.adminArea1,
              result.shipping.postalCode,
              result.shipping.countryCode
            ].filter((item) => item && !/^\s*$/.test(item)).join(', ')
          }

          OrderItemsStore.addShipping(result.shipping)
          OrderItemsStore.addTax(result.tax)

          this.submittedTaxAndShippingCalculation = result

          return resolve(this.submittedTaxAndShippingCalculation)
        }).fail((error) => {
          return reject(error)
        })
      }
    })
  }

  disabledSubmitButton () {
    this.$form.find('[type="submit"]').attr('disabled', true).addClass('disabled')
  }

  enableSubmitButton () {
    this.$form.find('[type="submit"]').removeAttr('disabled').removeClass('disabled')
  }

  renderItemPrice (item) {
    if (currency && item.availablePrices) {
      const listingPrice = item.availablePrices.find(el => el.currency === currency.toUpperCase())

      if (listingPrice) {
        return listingPrice.display_price
      }
    }

    return 0
  }

  renderItemStatus (item) {
    if (item.listing_type === 'sold') {
      return `<span class="badge badge-warning">${I18n.t('sold')}</span>`
    } else if (!item.with_stock) {
      return `<span class="badge badge-warning">${I18n.t('out_of_stock')}</span>`
    }

    return ''
  }

  paypalOrderParams () {
    const cartData = OrderItemsStore.getItemsWithTotalValues(this.reducer, currency)

    if (cartData.subtotalPrice <= 0) return

    const items = cartData.items.map((item) => {
      const price = item.availablePrices.find(el => el.currency === currency.toUpperCase())

      return {
        name: item.title,
        unit_amount: {
          currency_code: currency,
          value: price.actual_price.toFixed(DECIMAL_PLACES)
        },
        quantity: item.quantity,
        category: 'PHYSICAL_GOODS'
      }
    })

    if (!validPaypalCurrencies.includes(currency)) {
      console.log('Currency is not supported.')
    }

    let orderParams = {
      purchase_units: [{
        amount: {
          currency_code: currency,
          value: cartData.totalPrice.toFixed(DECIMAL_PLACES)
        },
        items: items
      }]
    }

    if (this.paypalShippingEnabled) {
      orderParams.application_context = {
        shipping_preference: 'SET_PROVIDED_ADDRESS'
      }

      orderParams.purchase_units[0].shipping = {
        name: {
          full_name: cartData.shipping.fullName
        },
        address: {
          address_line_1: cartData.shipping.addressLine1,
          address_line_2: cartData.shipping.addressLine2,
          admin_area_2: cartData.shipping.adminArea2,
          admin_area_1: cartData.shipping.adminArea1,
          postal_code: cartData.shipping.postalCode,
          country_code: cartData.shipping.countryCode
        }
      }
    }

    if (cartData.subtotalPrice > 0) {
      orderParams.purchase_units[0].amount.breakdown = {}
      orderParams.purchase_units[0].amount.breakdown.item_total = {
        currency_code: currency,
        value: cartData.subtotalPrice.toFixed(DECIMAL_PLACES)
      }
    }

    if (cartData.totalTax > 0) {
      orderParams.purchase_units[0].amount.breakdown.tax_total = {
        currency_code: currency,
        value: cartData.totalTax.toFixed(DECIMAL_PLACES)
      }
    }

    if (cartData.shippingPrice > 0) {
      orderParams.purchase_units[0].amount.breakdown.shipping = {
        currency_code: currency,
        value: cartData.shippingPrice.toFixed(DECIMAL_PLACES)
      }
    }

    return orderParams
  }

  renderPaypal () {
    const orderParams = this.paypalOrderParams()

    if (!orderParams) return

    paypal.Buttons({
      style: {
        label: 'pay'
      },
      createOrder: (_data, actions) => {
        return actions.order.create(orderParams)
      },
      onApprove: (_data, actions) => {
        this.$parent.closest('.modal').css('display', 'none')

        return actions.order.capture().then((details) => {
          const purchaseUnit = details.purchase_units[0]
          const payment = purchaseUnit.payments.captures[0]
          const paymentStatus = payment.status.toLowerCase()
          const breakdown = purchaseUnit.amount.breakdown

          let orderResultData = {
            email: details.payer.email_address,
            first_name: details.payer.name.given_name,
            last_name: details.payer.name.surname,
            phone: details.payer.phone?.phone_number?.national_number,
            shipping_address: Object.values(purchaseUnit.shipping.address).join(', '),
            total_price: payment.amount.value,
            currency: payment.amount.currency_code,
            message: `${I18n.t('ship_to')}: ${purchaseUnit.shipping.name.full_name}`,
            items_attributes: OrderItemsStore.getValidItems(),
            paypal_payment_attributes: {
              transaction_id: payment.id,
              status: paymentStatus === 'completed' ? 'received' : paymentStatus,
              amount: payment.amount.value,
              currency: payment.amount.currency_code
            }
          }

          if (breakdown && breakdown.item_total && breakdown.item_total.value > 0) {
            orderResultData.subtotal_price = breakdown.item_total.value
          }

          if (breakdown && breakdown.shipping && breakdown.shipping.value > 0) {
            orderResultData.shipping_price = breakdown.shipping.value
          }

          if (breakdown && breakdown.tax_total && breakdown.tax_total.value > 0) {
            orderResultData.total_tax = breakdown.tax_total.value
          }

          $.ajax({
            url: '/api/orders',
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify({ order: orderResultData })
          }).done((_result) => {
            OrderItemsStore.clear()
            this.trackOrder(orderResultData)
            this.isSuccess = true
            this.render()
          }).catch((error) => {
            if (error.status === 422) {
              const errors = error.responseJSON['errors'].map((error) => {
                return error.detail
              }).join('<br>')

              this.orderValidationErrorMessage = errors
            }

            OrderItemsStore.clear()
            this.paypalTransactionId = payment.id
            this.isFailure = true
            this.render()
          }).always(() => {
            this.$parent.closest('.modal').css('display', 'block')
          })
        })
      },
      onError: (_error) => {
        const message = I18n.t('paypal_sww_please_contact_us')
        this.$parent.closest('.modal').find('.error-message').html(message)
        this.$parent.closest('.modal').find('#error_alert').show()
      }
    }).render('#paypal-button-container')
  }

  trackOrder (params) {
    new Track().send('event', {
      event_category: 'order_submit'
    })

    if (window.onOrderSubmitted && typeof window.onOrderSubmitted === 'function') {
      window.onOrderSubmitted({
        value: params.total_price,
        currency: params.currency,
        transaction_id: params.paypal_payment_attributes.transaction_id,
        items: params.items_attributes,
        tax: params.total_tax,
        shipping: params.shipping_price
      })
    }
  }

  reducer (sum, item) {
    if (currency && item.availablePrices) {
      const listingPrice = item.availablePrices.find(price => price.currency === currency.toUpperCase())

      if (listingPrice) {
        if (currency === 'JPY') {
          return sum + parseInt(listingPrice.actual_price) * item.quantity
        } else {
          return sum + parseFloat(listingPrice.actual_price) * item.quantity
        }
      }
    }

    return 0
  }

  get formData () {
    if (!this.$form) return { order: {} }

    const order = this.$form.serializeArray().reduce((acc, item) => {
      acc[item.name] = item.value
      return acc
    }, {})

    order.currency = currency

    return order
  }

  get template () {
    if (this.isLoading) {
      return this.loadingTemplate
    } else if (this.isFailure) {
      return this.failureTemplate
    } else if (this.isSuccess) {
      return this.successTemplate
    } else if (!this.paypalShippingEnabled || this.shippingCalculated) {
      return this.paypalFormTemplate
    } else {
      return this.shippingFormTemplate
    }
  }

  get loadingTemplate () {
    return `
    <div class="text-center">
      <h1><i class="fa fa-spin fa-spinner text-grey"></i></h1>
    </div>
    `
  }

  get failureTemplate () {
    return `
    <div class="text-center">
      <h5>${I18n.t('paypal_sww_please_contact_us')}</h5>
      ${this.paypalTransactionId ? `<h5>${I18n.t('your_paypal_transaction_id')}: ${this.paypalTransactionId}</h5>` : ''}
      ${this.orderValidationErrorMessage ? `${this.orderValidationErrorMessage}` : ''}
    </div>
    `
  }

  get successTemplate () {
    return `
    <div class="text-center">
      <h5>${I18n.t('thank_you_for_your_order')}</h5>
      <h5>${I18n.t('we_will_contact_shortly')}</h5>
    </div>
    `
  }

  get paypalFormTemplate () {
    const cartData = OrderItemsStore.getItemsWithTotalValues(this.reducer, currency)
    const showQuantity = WebsiteConfigs.showListingQuantity

    return `
      <div>
        <div class="alert alert-danger" id="error_alert" style="display: none;">
          <div class="error-message"></div>
        </div>
        <div class="d-none d-md-block">
          <div class="d-flex mb-2">
            <div class="d-flex" style="width: 350px">
              <div class="mr-3">#</div>
              <div>${I18n.t('title')}</div>
            </div>
            <div class="d-flex mb-1 text-sm-right justify-content-end w-100">
              <div class="mr-3 text-left">${I18n.t('price')}</div>
              ${showQuantity ? `<div class="mr-3 text-center" style="width: 110px">${I18n.t('quantity')}</div>` : ''}
            </div>
          </div>
        </div>
        ${cartData.items.map((item, index) => `
          <div data-index="${index}" class="item-index d-flex flex-wrap justify-content-between mb-2 align-items-start">
            <div class="d-flex mb-1 tr" style="width: 350px;">
              <div class="mr-3 scope="row">${index + 1}</div>
              <div style="max-width: 350px;" class="item-title"><a class="mr-2" href="/listings/${item.listing_id}">${item.title}</a>
                ${this.renderItemStatus(item)}
              </div>
            </div>
            <div class="d-flex mb-1 text-sm-right justify-content-end w-md-100 w-auto">
              <div class="mr-3">${this.renderItemPrice(item)}</div>
              ${showQuantity ? `
                <div class="mr-3">
                  <div class="text-center" style="width: 110px;">${item.quantity}</div>
                </div>
              ` : ''}
            </div>
          </div>`).join('')}
        ${this.paypalShippingEnabled ? `
          <div class="text-right mr-2">
            <b>${I18n.t('ship_to')}</b>
            <br />
            ${cartData.shipping.fullName}
            <br />
            ${cartData.shipping.shippingAddress}
          </div>
        ` : ''}
        <div class="mr-2">
          <div class="text-right">
            <hr />
            ${cartData.totalTax > 0 || cartData.shippingPrice > 0 ? `
              <p>${I18n.t('subtotal')}: ${Currency.formatPrice(cartData.subtotalPrice, HIDE_CURRENCY_ISO_CODE, DECIMAL_PLACES)}</p>
            ` : ''}
            ${cartData.totalTax > 0 ? `
              <p>${I18n.t('tax')}: ${Currency.formatPrice(cartData.totalTax, HIDE_CURRENCY_ISO_CODE, DECIMAL_PLACES)}</p>
            ` : ''}
            ${cartData.shippingPrice > 0 ? `
              <p>${I18n.t('shipping')}: ${Currency.formatPrice(cartData.shippingPrice, HIDE_CURRENCY_ISO_CODE, DECIMAL_PLACES)}</p>
            ` : ''}
            ${cartData.totalTax > 0 || cartData.shippingPrice > 0 ? `
              <hr />
            ` : ''}
            <h4>${I18n.t('total')}: ${Currency.formatPrice(cartData.totalPrice, HIDE_CURRENCY_ISO_CODE, DECIMAL_PLACES)}</h4>
          </div>
        </div>
        <div class="mt-3 text-center" id="paypal-button-container"></div>
        <button class="mt-1 btn shipping-calculation--back col-12">
          <i class="fa fa-chevron-left"></i>
          ${I18n.t('back')}
        </button>
      </div>
    `
  }

  get shippingFormTemplate () {
    const shippingData = OrderItemsStore.getShippingData()

    return `
      <form id="checkout_form" novalidate="novalidate">
        <div class="alert alert-danger" id="error_alert" style="display: none;">
          <div class="error-message"></div>
        </div>
        <div class="form-row">
          <div class="form-group col-12">
            <label for="full_name">${I18n.t('full_name')}</label>
            <input type="text" value="${shippingData.fullName || ''}" name="full_name" class="form-control">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="address_line_1">${I18n.t('address_line_1')}</label>
            <input type="text" value="${shippingData.addressLine1 || ''}" name="address_line_1" class="form-control">
          </div>
          <div class="form-group col-6">
            <label for="address_line_2">${I18n.t('address_line_2')}</label>
            <input type="text" value="${shippingData.addressLine2 || ''}" name="address_line_2" class="form-control">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="city">${I18n.t('city')}</label>
            <input type="text" value="${shippingData.adminArea2 || ''}" name="city" class="form-control">
          </div>
          <div class="form-group col-6">
            <label for="region_state">${I18n.t('region_state')}</label>
            <input type="text" value="${shippingData.adminArea1 || ''}" name="region_state" class="form-control">
          </div>
        </div>

        <div class="form-row">
          <div class="form-group col-6">
            <label for="postal_code">${I18n.t('postal_code')}</label>
            <input type="text" value="${shippingData.postalCode || ''}" name="postal_code" class="form-control">
          </div>
          <div class="form-group col-6">
            <label for="country_code">${I18n.t('country')}</label>
            <select class="custom-select" name="country_code">
              ${COUNTRIES.map((country) => `
                <option
                  value="${country.code}"
                  ${country.code === shippingData.countryCode ? 'selected="selected"' : ''}
                >
                  ${country.name}
                </option>`)}
            </select>
          </div>
        </div>

        <div>
          <button name="button" type="submit" class="btn btn-success col-12">
            ${I18n.t('continue')}
          </button>
          <div class="btn shopping-cart--back col-12 mt-2">
            <i class="fa fa-chevron-left"></i>
            ${I18n.t('back')}
          </div>
        </div>
      </form>
    `
  }
}

export default PaypalCheckoutForm
