/* global I18n */

import OrderItemsStore from './order_items_store'
import Track from 'tracking_events/scripts/track'
import WebsiteConfigs from 'website_configs/website_configs'
import Currency from 'utils/currency'

const currency = Currency.getCurrency()

class CheckoutForm {
  static render ($parent, params) {
    return new CheckoutForm($parent, params)
  }

  constructor ($parent, params = {}) {
    if (params['withOffer']) {
      this.withOffer = true
      this.totalPrice = params['totalPrice']
    }

    this.$parent = $parent

    if (WebsiteConfigs.createWebshopLead) {
      this.loadAndRenderLeadForm()
    } else {
      this.render()
    }
  }

  get requiredCustomFieldIds () {
    return $.map($('[data-required=true]'), val => val.id)
  }

  get customlRules () {
    return this.requiredCustomFieldIds.reduce((object, id) => {
      object[id.replace('_', ' ')] = { required: true }
      return object
    }, {})
  }

  loadAndRenderLeadForm () {
    $.getJSON('/api/leads/new', { id: 'webshop_lead_form' }, (json) => {
      this.leadTemplate = json.html
      this.render()
    })
  }

  render () {
    this.$parent.html(this.template).ready(() => {
      this.$form = this.$parent.find('form')
      this.privacyCheckbox = this.$form.find("[name='privacy_policy']")
      this.bindEvents()
      this.prefillForm()
      this.bindPrivacyCheckbox()
    })
  }

  bindEvents () {
    this.$form.validate({
      submitHandler: () => { this.onSubmit() },
      rules: {
        offer: {
          required: true
        },
        first_name: {
          required: true
        },
        last_name: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        shipping_address: {
          required: true
        },
        phone: {
          required: true
        },
        message: {
          required: !WebsiteConfigs.createWebshopLead
        },
        terms_of_sales_checkbox: {
          required: true
        },
        ...this.customlRules
      },
      messages: {
        offer: {
          required: I18n.t('validation.offer')
        },
        first_name: {
          required: I18n.t('validation.first_name')
        },
        last_name: {
          required: I18n.t('validation.last_name')
        },
        email: {
          required: I18n.t('validation.email'),
          email: I18n.t('validation.email')
        },
        phone: {
          required: I18n.t('validation.phone')
        },
        shipping_address: {
          required: I18n.t('validation.shipping_address')
        },
        message: {
          required: I18n.t('validation.message')
        },
        terms_of_sales_checkbox: {
          required: I18n.t('validation.terms_of_sales_checkbox')
        }
      }
    })
  }

  onSubmit () {
    event.preventDefault()

    this.disabledSubmitButton()

    this.createOrder().then((result) => {
      this.onSuccess()
    }).catch((error) => {
      this.onError(error)
    }).finally(() => {
      this.enableSubmitButton()
    })
  }

  createOrder () {
    return new Promise((resolve, reject) => {
      if (this.submittedOrder) {
        return resolve(this.submittedOrder)
      } else {
        $.ajax({
          url: '/api/orders',
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({ order: { utm_params: JSON.parse(sessionStorage.getItem('utm_params') || '{}'),
            ...this.formData } })
        }).done((result) => {
          this.submittedOrder = result

          return resolve(this.submittedOrder)
        }).fail((error) => {
          return reject(error)
        })
      }
    })
  }

  onSuccess () {
    this.isSuccess = true

    localStorage.setItem('contacts', JSON.stringify({
      ...this.formData,
      message: null,
      offer: null
    }))

    this.trackOrder()
    OrderItemsStore.clear()

    this.render()
  }

  onError (error) {
    if (error.status === 422) {
      const errors = JSON.parse(error.responseText)['errors'].map((error) => {
        return error.detail
      }).join('<br>')

      this.$form.find('.error-message').html(errors)
      this.$form.find('#error_alert').show()
    }
  }

  prefillForm () {
    const contacts = JSON.parse(localStorage.getItem('contacts') || '{}')

    if (contacts) {
      this.$form.find('textarea, input:not([type="submit"])').each((index, input) => {
        const value = contacts[input.name]
        if (value) {
          input.value = value
        }
      })
    }
  }

  disabledSubmitButton () {
    this.$form.find('[type="submit"]').attr('disabled', true)
  }

  enableSubmitButton () {
    this.$form.find('[type="submit"]').removeAttr('disabled')
  }

  bindPrivacyCheckbox () {
    this.privacyCheckbox.on('change', (e) => e.target.checked ? this.enableSubmitButton() : this.disabledSubmitButton())
  }

  trackOrder () {
    new Track().send('event', {
      event_category: 'order_submit'
    })

    if (window.gtagReportConversion) {
      window.gtagReportConversion()
    }

    if (window.onOrderSubmitted && typeof window.onOrderSubmitted === 'function') {
      window.onOrderSubmitted({
        listing_ids: this.formData.items_attributes.map(el => el.listing_id),
        items: this.formData.items_attributes,
        value: this.totalPrice,
        currency: this.formData.currency
      })
    }
  }

  get formData () {
    const order = this.$form.serializeArray().reduce((acc, item) => {
      acc[item.name] = item.value
      return acc
    }, {})

    order.items_attributes = OrderItemsStore.getValidItems()
    order.currency = currency

    return order
  }

  get template () {
    if (this.isSuccess) {
      return `
        <div class="text-center">
          ${this.withOffer ? `<h5>${I18n.t('thank_you_for_your_offer')}</h5>` : `
          <h5>${I18n.t(WebsiteConfigs.createWebshopLead ? 'thank_you_for_your_inquiry_short' : 'thank_you_for_your_order')}</h5>
          <h5>${I18n.t('we_will_contact_shortly')}</h5>`}
        </div>
        `
    } else {
      return this.leadTemplate || `
        <form id="checkout_form" novalidate="novalidate">
          ${(WebsiteConfigs.showWebshopStripeInvoiceCheckoutHint && !this.withOffer) ? `
            <h5 class="mb-4">
              ${I18n.t('thank_you_for_selecting_items')}
            </h5>` : ''}
          ${this.withOffer ? `
            <h5 class="mb-4">
              ${I18n.t('total_price') + ': ' + this.totalPrice}
            </h5>` : ''}
          <div class="alert alert-danger" id="error_alert" style="display: none;">
            <div class="error-message"></div>
          </div>
          ${this.withOffer ? `
            <div class="form-group">
              <label for="offer">${I18n.t('offer') + ' (' + currency + ')'}</label>
              <input type="number" min="0" name="offer" class="form-control">
            </div>` : ''}
          <div class="form-row">
            <div class="form-group col-6">
              <label for="first_name">${I18n.t('first_name')}</label>
              <input type="text" name="first_name" class="form-control">
            </div>
            <div class="form-group col-6">
              <label for="last_name">${I18n.t('last_name')}</label>
              <input type="text" name="last_name" class="form-control">
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-6">
              <label for="phone">${I18n.t('phone')}</label>
              <input type="text" name="phone" class="form-control">
            </div>
            <div class="form-group col-6">
              <label for="email">${I18n.t('email')}</label>
              <input type="text" name="email" class="form-control">
            </div>
          </div>
          ${WebsiteConfigs.createWebshopLead ? '' : `<div class="form-group">
              <label for="shipping_address">${I18n.t('shipping_address')}</label>
              <textarea name="shipping_address" class="form-control" placeholder="${I18n.t('please_provide_your_full_shipping_address')}"></textarea>
            </div>`}
          <div class="form-group">
            <label for="message">${I18n.t('message')}</label>
            <textarea name="message" class="form-control"></textarea>
          </div>
          ${
  WebsiteConfigs.gdprCompliance ? `<div class="form-check mb-3">
              <label>
                <input type="checkbox" name="receive_newsletters" value="" class="form-check-input">
                ${I18n.t('receive_newsletters')}
              </label>
            </div>` : ''
}
          ${(this.withOffer && WebsiteConfigs.termsOfSalesPath) ? `<div class="form-check mb-3">
            <label>
                <input type="checkbox" name="terms_of_sales_checkbox" value="" class="form-check-input">
                ${I18n.t('by_submitting_an_offer')}
                <a href=${WebsiteConfigs.termsOfSalesPath} target='_blank'>${I18n.t('terms_of_sales')}</a>
                ${I18n.t('should_my_offer_be_accepted')}
              </label>
            </div>` : ''}
          <div class="form-group">
            <button name="button" type="submit" class="btn btn-primary col-12">${I18n.t('submit')}</button>
          </div>
        </form>
      `
    }
  }
}

export default CheckoutForm
