/* global I18n */

class ShippingAddress {
  static render ($parent, onBack, onCheckout) {
    return new ShippingAddress($parent, onBack, onCheckout)
  }

  constructor ($parent, onBack, onCheckout) {
    this.$parent = $parent
    this.onBack = onBack
    this.onCheckout = onCheckout
    this.render()
  }

  render () {
    this.$parent.html(this.template).ready(() => {
      this.$element = this.$parent.children().first()
      this.$form = this.$element.find('form')
      this.bindEvents()
      this.fetchCountriesList()
    })
  }

  bindEvents = () => {
    this.$element.find('.shopping-cart--back').on('click', this.handleBackClick)
    this.$element.find('.shopping-cart--stripe-checkout').on('click', this.handleCheckoutClick)
  }

  handleBackClick = (e) => {
    e.preventDefault()
    this.onBack()
  }

  handleCheckoutClick = (e) => {
    e.preventDefault()
    this.hideErrorMessage()

    if (!this.validateForm()) {
      return
    }

    const $targetEl = $(e.currentTarget)
    const originalText = $targetEl.find('span').text()

    $targetEl.prop('disabled', true)
    $targetEl.find('span').text(I18n.t('processing'))

    const shippingAddress = this.serializeForm()

    this.onCheckout(shippingAddress).catch((error) => {
      this.handleCheckoutError(error, $targetEl, originalText)
    }).then((checkoutUrl) => {
      if (checkoutUrl) {
        window.location.href = checkoutUrl
      }
    })
  }

  validateForm = () => {
    let isValid = true
    this.$form.find('input[required]').each((index, input) => {
      const $input = $(input)
      if (!$input.val()) {
        isValid = false
        $input.addClass('is-invalid')
        $input.next('.invalid-feedback').text(I18n.t('required'))
      } else {
        $input.removeClass('is-invalid')
        $input.next('.invalid-feedback').text('')
      }
    })
    return isValid
  }

  serializeForm = () => {
    return this.$form.serializeArray().reduce((acc, item) => {
      acc[item.name] = item.value
      return acc
    }, {})
  }

  fetchCountriesList () {
    $.get('/api/countries')
      .then((response) => {
        this.populateCountriesList(response)
      }).catch((error) => {
        console.error(error)
        this.showErrorMessage(I18n.t('could_not_load_countries'))
      })
  }

  populateCountriesList = (countries) => {
    const $countrySelect = this.$element.find('#country')
    let allowedCountries = $('[data-shipping-countries]').data('shipping-countries') || []

    countries.forEach((country) => {
      if (allowedCountries.length > 0 && !allowedCountries.includes(country.iso_code)) {
        return
      }

      $countrySelect.append(`<option value="${country.iso_code}">${country.name}</option>`)
    })
  }

  handleCheckoutError = (error, $targetEl, originalText) => {
    if (error.status === 422) {
      const { shipping_address: shippingAddress, ...otherErrors } = error.responseJSON['errors']

      if (shippingAddress) {
        Object.entries(shippingAddress).forEach(([key, value]) => {
          $(`#${key}`).addClass('is-invalid')
          $(`#${key}_error`).text(value)
        })
      }

      const errors = Object.entries(otherErrors).map(([_, values]) => values).join('<br>')

      if (errors.length > 0) {
        console.error(errors)
        this.showErrorMessage(errors)
      }
    } else {
      console.error(error)
      this.showErrorMessage(I18n.t('checkout_error'))
    }

    $targetEl.prop('disabled', false)
    $targetEl.find('span').text(originalText)
  }

  hideErrorMessage = () => {
    $('#error_alert').hide()
  }

  showErrorMessage = (message) => {
    $('#error_alert').show()
    $('#error_alert .error-message').html(message)
  }

  get template () {
    return `
      <div class="shipping_details">
        <form>
          <div class="d-flex mb-2">
            <h3>${I18n.t('shipping_address')}</h3>
          </div>
          <div class="d-none d-md-block">
            <div class="alert alert-danger" id="error_alert" style="display: none;">
              <div class="error-message"></div>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="email">${I18n.t('email')}</label>
              <input type="text" class="form-control" id="email" name="email" placeholder="${I18n.t('email')}" required>
              <div class="invalid-feedback" id="email_error"></div>
            </div>
            <div class="form-group col-md-4">
              <label for="full_name">${I18n.t('full_name')}</label>
              <input type="text" class="form-control" id="full_name" name="full_name" placeholder="${I18n.t('full_name')}" required>
              <div class="invalid-feedback" id="full_name_error"></div>
            </div>
            <div class="form-group col-md-4">
              <label for="phone">${I18n.t('phone')}</label>
              <input type="text" class="form-control" id="phone" name="phone" placeholder="${I18n.t('phone')}" required>
              <div class="invalid-feedback" id="phone_error"></div>
            </div>
          </div>
          <div class="form-group">
            <label for="address_line_1">${I18n.t('address_line_1')}</label>
            <input type="text" class="form-control" id="address_line_1" name="address_line_1" placeholder="${I18n.t('address_line_1')}" required>
            <div class="invalid-feedback" id="address_line_1_error"></div>
          </div>
          <div class="form-group">
            <label for="address_line_2">${I18n.t('address_line_2')}</label>
            <input type="text" class="form-control" id="address_line_2" name="address_line_2" placeholder="${I18n.t('address_line_2')}">
            <div class="invalid-feedback" id="address_line_2_error"></div>
          </div>
          <div class="form-group">
            <label for="city">${I18n.t('city')}</label>
            <input type="text" class="form-control" id="city" name="city" placeholder="${I18n.t('city')}" required>
            <div class="invalid-feedback" id="city_error"></div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="region_state">${I18n.t('region_state')}</label>
              <input type="text" class="form-control" id="region_state" name="region_state" placeholder="${I18n.t('region_state')}" required>
              <div class="invalid-feedback" id="region_state_error"></div>
            </div>
            <div class="form-group col-md-6">
              <label for="postal_code">${I18n.t('postal_code')}</label>
              <input type="text" class="form-control" id="postal_code" name="postal_code" placeholder="${I18n.t('postal_code')}">
              <div class="invalid-feedback" id="postal_code_error"></div>
            </div>
          </div>
          <div class="form-group">
            <label for="country">${I18n.t('country')}</label>
            <select class="form-control" id="country" name="country" required>
              <option value="">${I18n.t('country')}</option>
            </select>
            <div class="invalid-feedback" id="country_error"></div>
          </div>
          <hr>
          <div>
            <button class="btn btn-secondary shopping-cart--back">
              <i class="fa fa-chevron-left" aria-hidden="true"></i>
              ${I18n.t('back')}
            </button>
            <button class="btn btn-success shopping-cart--stripe-checkout checkout-button-margin">
              <i class="fa fa-shopping-cart" aria-hidden="true"></i>
              <span>${I18n.t('checkout')}</span>
            </button>
          </div>
        </form>
      </div>
    `
  }
}

export default ShippingAddress
