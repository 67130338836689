const COUNTRIES = [
  { 'name': 'ALBANIA', 'code': 'AL' },
  { 'name': 'ALGERIA', 'code': 'DZ' },
  { 'name': 'ANDORRA', 'code': 'AD' },
  { 'name': 'ANGOLA', 'code': 'AO' },
  { 'name': 'ANGUILLA', 'code': 'AI' },
  { 'name': 'ANTIGUA & BARBUDA', 'code': 'AG' },
  { 'name': 'ARGENTINA', 'code': 'AR' },
  { 'name': 'ARMENIA', 'code': 'AM' },
  { 'name': 'ARUBA', 'code': 'AW' },
  { 'name': 'AUSTRALIA', 'code': 'AU' },
  { 'name': 'AUSTRIA', 'code': 'AT' },
  { 'name': 'AZERBAIJAN', 'code': 'AZ' },
  { 'name': 'BAHAMAS', 'code': 'BS' },
  { 'name': 'BAHRAIN', 'code': 'BH' },
  { 'name': 'BARBADOS', 'code': 'BB' },
  { 'name': 'BELARUS', 'code': 'BY' },
  { 'name': 'BELGIUM', 'code': 'BE' },
  { 'name': 'BELIZE', 'code': 'BZ' },
  { 'name': 'BENIN', 'code': 'BJ' },
  { 'name': 'BERMUDA', 'code': 'BM' },
  { 'name': 'BHUTAN', 'code': 'BT' },
  { 'name': 'BOLIVIA', 'code': 'BO' },
  { 'name': 'BOSNIA & HERZEGOVINA', 'code': 'BA' },
  { 'name': 'BOTSWANA', 'code': 'BW' },
  { 'name': 'BRAZIL', 'code': 'BR' },
  { 'name': 'BRITISH VIRGIN ISLANDS', 'code': 'VG' },
  { 'name': 'BRUNEI', 'code': 'BN' },
  { 'name': 'BULGARIA', 'code': 'BG' },
  { 'name': 'BURKINA FASO', 'code': 'BF' },
  { 'name': 'BURUNDI', 'code': 'BI' },
  { 'name': 'CAMBODIA', 'code': 'KH' },
  { 'name': 'CAMEROON', 'code': 'CM' },
  { 'name': 'CANADA', 'code': 'CA' },
  { 'name': 'CAPE VERDE', 'code': 'CV' },
  { 'name': 'CAYMAN ISLANDS', 'code': 'KY' },
  { 'name': 'CHAD', 'code': 'TD' },
  { 'name': 'CHILE', 'code': 'CL' },
  { 'name': 'CHINA', 'code': 'C2' },
  { 'name': 'COLOMBIA', 'code': 'CO' },
  { 'name': 'COMOROS', 'code': 'KM' },
  { 'name': 'CONGO - BRAZZAVILLE', 'code': 'CG' },
  { 'name': 'CONGO - KINSHASA', 'code': 'CD' },
  { 'name': 'COOK ISLANDS', 'code': 'CK' },
  { 'name': 'COSTA RICA', 'code': 'CR' },
  { 'name': 'CÔTE D’IVOIRE', 'code': 'CI' },
  { 'name': 'CROATIA', 'code': 'HR' },
  { 'name': 'CYPRUS', 'code': 'CY' },
  { 'name': 'CZECH REPUBLIC', 'code': 'CZ' },
  { 'name': 'DENMARK', 'code': 'DK' },
  { 'name': 'DJIBOUTI', 'code': 'DJ' },
  { 'name': 'DOMINICA', 'code': 'DM' },
  { 'name': 'DOMINICAN REPUBLIC', 'code': 'DO' },
  { 'name': 'ECUADOR', 'code': 'EC' },
  { 'name': 'EGYPT', 'code': 'EG' },
  { 'name': 'EL SALVADOR', 'code': 'SV' },
  { 'name': 'ERITREA', 'code': 'ER' },
  { 'name': 'ESTONIA', 'code': 'EE' },
  { 'name': 'ETHIOPIA', 'code': 'ET' },
  { 'name': 'FALKLAND ISLANDS', 'code': 'FK' },
  { 'name': 'FAROE ISLANDS', 'code': 'FO' },
  { 'name': 'FIJI', 'code': 'FJ' },
  { 'name': 'FINLAND', 'code': 'FI' },
  { 'name': 'FRANCE', 'code': 'FR' },
  { 'name': 'FRENCH GUIANA', 'code': 'GF' },
  { 'name': 'FRENCH POLYNESIA', 'code': 'PF' },
  { 'name': 'GABON', 'code': 'GA' },
  { 'name': 'GAMBIA', 'code': 'GM' },
  { 'name': 'GEORGIA', 'code': 'GE' },
  { 'name': 'GERMANY', 'code': 'DE' },
  { 'name': 'GIBRALTAR', 'code': 'GI' },
  { 'name': 'GREECE', 'code': 'GR' },
  { 'name': 'GREENLAND', 'code': 'GL' },
  { 'name': 'GRENADA', 'code': 'GD' },
  { 'name': 'GUADELOUPE', 'code': 'GP' },
  { 'name': 'GUATEMALA', 'code': 'GT' },
  { 'name': 'GUINEA', 'code': 'GN' },
  { 'name': 'GUINEA-BISSAU', 'code': 'GW' },
  { 'name': 'GUYANA', 'code': 'GY' },
  { 'name': 'HONDURAS', 'code': 'HN' },
  { 'name': 'HONG KONG SAR CHINA', 'code': 'HK' },
  { 'name': 'HUNGARY', 'code': 'HU' },
  { 'name': 'ICELAND', 'code': 'IS' },
  { 'name': 'INDIA', 'code': 'IN' },
  { 'name': 'INDONESIA', 'code': 'ID' },
  { 'name': 'IRELAND', 'code': 'IE' },
  { 'name': 'ISRAEL', 'code': 'IL' },
  { 'name': 'ITALY', 'code': 'IT' },
  { 'name': 'JAMAICA', 'code': 'JM' },
  { 'name': 'JAPAN', 'code': 'JP' },
  { 'name': 'JORDAN', 'code': 'JO' },
  { 'name': 'KAZAKHSTAN', 'code': 'KZ' },
  { 'name': 'KENYA', 'code': 'KE' },
  { 'name': 'KIRIBATI', 'code': 'KI' },
  { 'name': 'KUWAIT', 'code': 'KW' },
  { 'name': 'KYRGYZSTAN', 'code': 'KG' },
  { 'name': 'LAOS', 'code': 'LA' },
  { 'name': 'LATVIA', 'code': 'LV' },
  { 'name': 'LESOTHO', 'code': 'LS' },
  { 'name': 'LIECHTENSTEIN', 'code': 'LI' },
  { 'name': 'LITHUANIA', 'code': 'LT' },
  { 'name': 'LUXEMBOURG', 'code': 'LU' },
  { 'name': 'MACEDONIA', 'code': 'MK' },
  { 'name': 'MADAGASCAR', 'code': 'MG' },
  { 'name': 'MALAWI', 'code': 'MW' },
  { 'name': 'MALAYSIA', 'code': 'MY' },
  { 'name': 'MALDIVES', 'code': 'MV' },
  { 'name': 'MALI', 'code': 'ML' },
  { 'name': 'MALTA', 'code': 'MT' },
  { 'name': 'MARSHALL ISLANDS', 'code': 'MH' },
  { 'name': 'MARTINIQUE', 'code': 'MQ' },
  { 'name': 'MAURITANIA', 'code': 'MR' },
  { 'name': 'MAURITIUS', 'code': 'MU' },
  { 'name': 'MAYOTTE', 'code': 'YT' },
  { 'name': 'MEXICO', 'code': 'MX' },
  { 'name': 'MICRONESIA', 'code': 'FM' },
  { 'name': 'MOLDOVA', 'code': 'MD' },
  { 'name': 'MONACO', 'code': 'MC' },
  { 'name': 'MONGOLIA', 'code': 'MN' },
  { 'name': 'MONTENEGRO', 'code': 'ME' },
  { 'name': 'MONTSERRAT', 'code': 'MS' },
  { 'name': 'MOROCCO', 'code': 'MA' },
  { 'name': 'MOZAMBIQUE', 'code': 'MZ' },
  { 'name': 'NAMIBIA', 'code': 'NA' },
  { 'name': 'NAURU', 'code': 'NR' },
  { 'name': 'NEPAL', 'code': 'NP' },
  { 'name': 'NETHERLANDS', 'code': 'NL' },
  { 'name': 'NEW CALEDONIA', 'code': 'NC' },
  { 'name': 'NEW ZEALAND', 'code': 'NZ' },
  { 'name': 'NICARAGUA', 'code': 'NI' },
  { 'name': 'NIGER', 'code': 'NE' },
  { 'name': 'NIGERIA', 'code': 'NG' },
  { 'name': 'NIUE', 'code': 'NU' },
  { 'name': 'NORFOLK ISLAND', 'code': 'NF' },
  { 'name': 'NORWAY', 'code': 'NO' },
  { 'name': 'OMAN', 'code': 'OM' },
  { 'name': 'PALAU', 'code': 'PW' },
  { 'name': 'PANAMA', 'code': 'PA' },
  { 'name': 'PAPUA NEW GUINEA', 'code': 'PG' },
  { 'name': 'PARAGUAY', 'code': 'PY' },
  { 'name': 'PERU', 'code': 'PE' },
  { 'name': 'PHILIPPINES', 'code': 'PH' },
  { 'name': 'PITCAIRN ISLANDS', 'code': 'PN' },
  { 'name': 'POLAND', 'code': 'PL' },
  { 'name': 'PORTUGAL', 'code': 'PT' },
  { 'name': 'QATAR', 'code': 'QA' },
  { 'name': 'RÉUNION', 'code': 'RE' },
  { 'name': 'ROMANIA', 'code': 'RO' },
  { 'name': 'RUSSIA', 'code': 'RU' },
  { 'name': 'RWANDA', 'code': 'RW' },
  { 'name': 'SAMOA', 'code': 'WS' },
  { 'name': 'SAN MARINO', 'code': 'SM' },
  { 'name': 'SÃO TOMÉ & PRÍNCIPE', 'code': 'ST' },
  { 'name': 'SAUDI ARABIA', 'code': 'SA' },
  { 'name': 'SENEGAL', 'code': 'SN' },
  { 'name': 'SERBIA', 'code': 'RS' },
  { 'name': 'SEYCHELLES', 'code': 'SC' },
  { 'name': 'SIERRA LEONE', 'code': 'SL' },
  { 'name': 'SINGAPORE', 'code': 'SG' },
  { 'name': 'SLOVAKIA', 'code': 'SK' },
  { 'name': 'SLOVENIA', 'code': 'SI' },
  { 'name': 'SOLOMON ISLANDS', 'code': 'SB' },
  { 'name': 'SOMALIA', 'code': 'SO' },
  { 'name': 'SOUTH AFRICA', 'code': 'ZA' },
  { 'name': 'SOUTH KOREA', 'code': 'KR' },
  { 'name': 'SPAIN', 'code': 'ES' },
  { 'name': 'SRI LANKA', 'code': 'LK' },
  { 'name': 'ST. HELENA', 'code': 'SH' },
  { 'name': 'ST. KITTS & NEVIS', 'code': 'KN' },
  { 'name': 'ST. LUCIA', 'code': 'LC' },
  { 'name': 'ST. PIERRE & MIQUELON', 'code': 'PM' },
  { 'name': 'ST. VINCENT & GRENADINES', 'code': 'VC' },
  { 'name': 'SURINAME', 'code': 'SR' },
  { 'name': 'SVALBARD & JAN MAYEN', 'code': 'SJ' },
  { 'name': 'SWAZILAND', 'code': 'SZ' },
  { 'name': 'SWEDEN', 'code': 'SE' },
  { 'name': 'SWITZERLAND', 'code': 'CH' },
  { 'name': 'TAIWAN', 'code': 'TW' },
  { 'name': 'TAJIKISTAN', 'code': 'TJ' },
  { 'name': 'TANZANIA', 'code': 'TZ' },
  { 'name': 'THAILAND', 'code': 'TH' },
  { 'name': 'TOGO', 'code': 'TG' },
  { 'name': 'TONGA', 'code': 'TO' },
  { 'name': 'TRINIDAD & TOBAGO', 'code': 'TT' },
  { 'name': 'TUNISIA', 'code': 'TN' },
  { 'name': 'TURKMENISTAN', 'code': 'TM' },
  { 'name': 'TURKS & CAICOS ISLANDS', 'code': 'TC' },
  { 'name': 'TUVALU', 'code': 'TV' },
  { 'name': 'UGANDA', 'code': 'UG' },
  { 'name': 'UKRAINE', 'code': 'UA' },
  { 'name': 'UNITED ARAB EMIRATES', 'code': 'AE' },
  { 'name': 'UNITED KINGDOM', 'code': 'GB' },
  { 'name': 'UNITED STATES', 'code': 'US' },
  { 'name': 'URUGUAY', 'code': 'UY' },
  { 'name': 'VANUATU', 'code': 'VU' },
  { 'name': 'VATICAN CITY', 'code': 'VA' },
  { 'name': 'VENEZUELA', 'code': 'VE' },
  { 'name': 'VIETNAM', 'code': 'VN' },
  { 'name': 'WALLIS & FUTUNA', 'code': 'WF' },
  { 'name': 'YEMEN', 'code': 'YE' },
  { 'name': 'ZAMBIA', 'code': 'ZM' },
  { 'name': 'ZIMBABWE', 'code': 'ZW' }
]

export default COUNTRIES
