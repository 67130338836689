import DynamicModal from 'modals/scripts/dynamic_modal'
import ShoppingCart from './shopping_cart'
import OrderItemsStore from './order_items_store'
import Track from 'tracking_events/scripts/track'
import WebsiteConfigs from 'website_configs/website_configs'

const ELEMENTS_SELECTOR = '[data-role="shopping-cart-icon"]'
const MODAL_SELECTOR = '#shopping_cart_modal'

class ShoppingCartFloatingButton {
  static async init () {
    return new ShoppingCartFloatingButton()
  }

  constructor () {
    this.$element = $(ELEMENTS_SELECTOR)

    this.updateCartItemCount()
    this.bindEvents()
  }

  updateCartItemCount () {
    const quantity = OrderItemsStore.getItems().reduce((sum, item) => sum + (item.quantity || 0), 0)
    this.$element.find('.floating-btn--indicator').text(quantity)
  }

  bindEvents () {
    this.$element.find('i').on('click', (e) => {
      e.preventDefault()

      const modal = new DynamicModal(MODAL_SELECTOR, { size: 'lg', header_title: WebsiteConfigs.shoppingCartHeaderTitle })
      ShoppingCart.render(modal.$body)
      modal.open()

      new Track().send('event', {
        event_category: 'shopping_cart_floating_button_click'
      })
    })

    OrderItemsStore.onChange((_event, data) => {
      const quantity = data.reduce((sum, item) => sum + (item.quantity || 0), 0)
      this.$element.find('.floating-btn--indicator').text(quantity)
    })
  }
}

export default ShoppingCartFloatingButton
