import WebsiteConfigs from 'website_configs/website_configs'
import Currency from 'utils/currency'

const ITEMS_LOCAL_STORAGE_KEY = 'mh:shopping_cart:items'
const TOTAL_PRICE_LOCAL_STORAGE_KEY = 'mh:shopping_cart:price'
const SHIPPING_LOCAL_STORAGE_KEY = 'mh:shopping_cart:shipping'
const TAX_LOCAL_STORAGE_KEY = 'mh:shopping_cart:tax'
const hideLeadQuantity = WebsiteConfigs.createWebshopLead && WebsiteConfigs.showListingQuantity
const currency = Currency.getCurrency()
const DECIMAL_PLACES = currency === 'JPY' ? 0 : 2

class OrderItemsStore {
  static callbacks = []

  static addItem (item) {
    const items = this.getItems()
    const foundItem = items.find((itm) => itm.listing_id === item.listing_id)

    if (foundItem) {
      if (foundItem.quantity >= foundItem.maxQuantity) return

      const index = items.indexOf(foundItem)
      foundItem.quantity += 1
      items[index] = foundItem

      localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(items))

      this.trigger('increaseQuantity', this.getItems())
    } else {
      item.quantity = 1
      localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(this.getItems().concat(item)))

      this.trigger('add', this.getItems())
    }
  }

  static updateItem (updatedItem) {
    const items = JSON.parse(localStorage.getItem(ITEMS_LOCAL_STORAGE_KEY) || '[]')
    const foundItem = items.find((itm) => itm.listing_id === updatedItem.listing_id)

    if (foundItem) {
      const index = items.indexOf(foundItem)

      if (updatedItem.maxQuantity === 0) {
        items[index].quantity = 0
      } else if (items[index].quantity > updatedItem.maxQuantity) {
        items[index].quantity = updatedItem.maxQuantity
      } else if (items[index].quantity === 0 && updatedItem.maxQuantity > 0) {
        items[index].quantity = 1
      }

      items[index] = {
        quantity: items[index].quantity,
        ...updatedItem
      }

      localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(items))

      this.trigger('update', this.getItems())
    }
  }

  static getItems () {
    return JSON.parse(localStorage.getItem(ITEMS_LOCAL_STORAGE_KEY) || '[]')
  }

  static getValidItems () {
    return this.getItems().filter(el => !['sold', 'hidden'].includes(el.listing_type) && el.with_stock)
  }

  static getShippingData () {
    return JSON.parse(localStorage.getItem(SHIPPING_LOCAL_STORAGE_KEY) || '{}')
  }

  static getTaxData () {
    return JSON.parse(localStorage.getItem(TAX_LOCAL_STORAGE_KEY) || '{}')
  }

  static getTotalPriceData () {
    return JSON.parse(localStorage.getItem(TOTAL_PRICE_LOCAL_STORAGE_KEY) || '{}')
  }

  static getItemsWithTotalValues (reducer, currency) {
    const items = this.getItems()
    const validItems = this.getValidItems()
    const shipping = this.getShippingData()
    const tax = this.getTaxData()
    let totalTax = 0
    let shippingPrice = 0
    let subtotalPrice = validItems.reduce(reducer, 0)

    if (currency === 'JPY') {
      subtotalPrice = Math.round(subtotalPrice)
    } else {
      subtotalPrice = Math.round(subtotalPrice * 100) / 100
    }

    if (shipping && shipping.type === 'fixed_rate_per_item') {
      const totalQuantity = validItems.reduce((sum, item) => sum + (item.quantity || 0), 0)
      shippingPrice = totalQuantity * shipping.rate
    } else if (shipping && shipping.type === 'percentage_of_total_price') {
      shippingPrice = 0.01 * subtotalPrice * shipping.rate
    }

    if (tax && tax.type === 'including_shipping') {
      totalTax = 0.01 * (subtotalPrice + shippingPrice) * tax.rate
    } else if (tax && tax.type === 'excluding_shipping') {
      totalTax = 0.01 * subtotalPrice * tax.rate
    }

    if (currency === 'JPY') {
      shippingPrice = Math.round(shippingPrice)
      totalTax = Math.round(totalTax)
    } else {
      shippingPrice = Math.round(shippingPrice * 100) / 100
      totalTax = Math.round(totalTax * 100) / 100
    }

    const totalPrice = subtotalPrice + shippingPrice + totalTax

    return {
      items: items,
      shippingPrice: shippingPrice,
      totalTax: totalTax,
      subtotalPrice: subtotalPrice,
      totalPrice: totalPrice,
      shipping: shipping,
      tax: tax
    }
  }

  static getItemIds () {
    return JSON.parse(localStorage.getItem(ITEMS_LOCAL_STORAGE_KEY) || '[]').map(el => el.listing_id)
  }

  static removeItem (index) {
    const items = this.getItems()
    items.splice(index, 1)

    localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(items))

    this.trigger('remove', this.getItems())
  }

  static addShipping (data) {
    localStorage.setItem(SHIPPING_LOCAL_STORAGE_KEY, JSON.stringify(data))
  }

  static addTax (data) {
    localStorage.setItem(TAX_LOCAL_STORAGE_KEY, JSON.stringify(data))
  }

  static addTotalPrice (data) {
    localStorage.setItem(TOTAL_PRICE_LOCAL_STORAGE_KEY, JSON.stringify(data))
  }

  static increaseQuantity (index) {
    const items = this.getItems()
    const item = items[index]

    if (item.quantity >= item.maxQuantity) return

    item.quantity += 1
    items[index] = item

    localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(items))

    this.trigger('increaseQuantity', this.getItems())
  }

  static decreaseQuantity (index) {
    const items = this.getItems()
    const item = items[index]

    if (item.quantity > 1) {
      item.quantity -= 1
      items[index] = item

      localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(items))

      this.trigger('decreaseQuantity', this.getItems())
    } else {
      items.splice(index, 1)

      localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(items))

      this.trigger('remove', this.getItems())
    }
  }

  static clear () {
    localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify([]))

    this.trigger('clear', this.getItems())
  }

  static onChange (callback) {
    this.callbacks.push(callback)
  }

  static trigger (event, data) {
    this.callbacks.forEach((callback) => callback(event, data))
  }

  static updateItemsAsync () {
    const listingIds = this.getItemIds()

    const promises = []
    if (listingIds.length > 0) {
      const listingsPromise = this.getAllItems(listingIds).then((res) => {
        return this.getFormattedPrice(this.totalPrice())
      })

      promises.push(listingsPromise)
    }

    return this.allSettled(promises)
  }

  static totalPrice () {
    return this.getValidItems().reduce(this.reducer, 0).toFixed(DECIMAL_PLACES)
  }

  static reducer (sum, item) {
    if (currency && item.availablePrices) {
      const listingPrice = item.availablePrices.find(price => price && price.currency === currency.toUpperCase())

      if (listingPrice) {
        return sum + parseFloat(listingPrice.actual_price) * item.quantity
      }
    }

    return 0
  }

  static async getAllItems (listingIds) {
    const promises = listingIds.map(listingId => {
      return this.getLatestListingInfo(listingId)
    })

    return this.allSettled(promises)
  }

  static async getLatestListingInfo (listingId) {
    return this.getListing(listingId)
      .then(result => {
        const listingId = result.data.id
        const {
          title,
          stock_number: stockNumber,
          price,
          extra_data: extraData,
          listing_type: listingType,
          formatted_primary_price: formattedPrimaryPrice,
          formatted_additional_prices: formattedAdditionalPrices
        } = result.data.attributes

        const { quantity } = extraData || {}

        let availablePrices = []

        formattedAdditionalPrices.forEach(additionalPrice => {
          additionalPrice.actual_price = Math.round(additionalPrice.actual_price * 100) / 100
          availablePrices.push(additionalPrice)
        })

        availablePrices.push(formattedPrimaryPrice)

        let maxQuantity = parseInt(quantity) || 1

        if (['sold', 'hidden'].includes(listingType) || parseInt(quantity) === 0) {
          maxQuantity = 0
        } else if (hideLeadQuantity) {
          maxQuantity = 1
        }

        const stockNumberOrId = stockNumber || listingId

        const latestItem = {
          maxQuantity,
          with_stock: maxQuantity > 0,
          title: [title, `#${stockNumberOrId}`].join(' ').trim(),
          price,
          availablePrices,
          listing_id: parseInt(listingId),
          listing_type: listingType
        }

        this.updateItem(latestItem)
      })
  }

  static getListing (listingId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/listings/${listingId}`,
        type: 'GET',
        success: (data) => {
          resolve(data)
        },
        error: (error) => {
          const self = this

          reject(error)
          self.updateListingIfNotFound(listingId, error)
        }
      })
    })
  }

  static getFormattedPrice (price) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/format_prices`,
        data: { price: price, currency: currency },
        type: 'GET',
        success: (data) => {
          resolve(data)
          this.addTotalPrice(data.formatted_price)
        },
        error: (error) => {
          reject(error)
        }
      })
    })
  }

  static updateListingIfNotFound (listingId, error) {
    const notFound = (/Listing not found/).test(error.responseText)
    if (notFound) {
      const items = this.getItems()
      const foundItem = items.find(el => el.listing_id === listingId)

      if (foundItem) {
        const index = items.indexOf(foundItem)
        items[index].with_stock = false
      }

      localStorage.setItem(ITEMS_LOCAL_STORAGE_KEY, JSON.stringify(items))
    }
  }

  static allSettled (promises) {
    let wrappedPromises = promises.map(p => Promise.resolve(p)
      .then(
        val => ({ status: 'fulfilled', value: val }),
        err => ({ status: 'rejected', reason: err })))
    return Promise.all(wrappedPromises)
  }
}

export default OrderItemsStore
