/* global I18n */

import OrderItemsStore from './order_items_store'
import CheckoutForm from './checkout_form'
import ShippingAddress from './shipping_address'
import PaypalCheckoutForm from './paypal_checkout_form'
import DynamicModal from 'modals/scripts/dynamic_modal'
import Currency from 'utils/currency'
import WebsiteConfigs from 'website_configs/website_configs'

const currency = Currency.getCurrency()
const SHOPPING_CART_CHECKOUT_MODAL_SELECTOR = '#shopping_cart_checkout_modal'
const SHOPPING_CART_CHECKOUT_WITH_ORDER_MODAL_SELECTOR = '#shopping_cart_checkout_with_offer_modal'

class ShoppingCart {
  static render ($parent) {
    return new ShoppingCart($parent)
  }

  constructor ($parent) {
    this.$parent = $parent
    this.render()
  }

  removeItem = (index) => {
    OrderItemsStore.removeItem(index)
    this.render()
  }

  increaseQuantity = (index) => {
    OrderItemsStore.increaseQuantity(index)
    this.render()
  }

  decreaseQuantity = (index) => {
    OrderItemsStore.decreaseQuantity(index)
    this.render()
  }

  maxQuantityReached = (item) => {
    return item.quantity === item.maxQuantity
  }

  submitOrder = () => {
    this.$parent.closest('.modal').modal('hide')
    const modal = new DynamicModal(SHOPPING_CART_CHECKOUT_MODAL_SELECTOR)
    CheckoutForm.render(modal.$body)
  }

  checkOut = (shippingAddress) => {
    return new Promise((resolve, reject) => {
      const items = OrderItemsStore.getItems()
      const listingItems = items.map((item) => ({
        listing_id: item.listing_id,
        quantity: item.quantity,
        currency: currency
      }))
      const successUrl = $('[data-checkout-success-url]').data('checkout-success-url')
      const cancelUrl = $('[data-checkout-cancel-url]').data('checkout-cancel-url')

      const checkoutParams = {
        currency: currency,
        newsletter_subscription: $('#receive_newsletters').is(':checked'),
        listing_items: listingItems,
        success_url: successUrl,
        cancel_url: cancelUrl,
        ...(shippingAddress && { shipping_address: shippingAddress })
      }

      $.post('/api/checkouts', { checkout: checkoutParams })
        .then((response) => {
          if (response.url) {
            resolve(response.url)
          } else {
            reject(new Error(I18n.t('checkout_error')))
          }
        })
        .fail((error) => {
          reject(error)
        })
    })
  }

  showErrorMessage = (message) => {
    $('#error_alert').show()
    $('#error_alert .error-message').html(message)
  }

  hideErrorMessage = () => {
    $('#error_alert').hide()
  }

  offerCheckOut = () => {
    this.$parent.closest('.modal').modal('hide')
    const modal = new DynamicModal(SHOPPING_CART_CHECKOUT_WITH_ORDER_MODAL_SELECTOR)
    CheckoutForm.render(modal.$body, { withOffer: true, totalPrice: this.totalPrice() })
    modal.open()
  }

  paypalCheckOut = () => {
    this.$parent.closest('.modal').modal('hide')
    const modal = new DynamicModal(SHOPPING_CART_CHECKOUT_MODAL_SELECTOR, { size: 'lg' })
    PaypalCheckoutForm.render(modal.$body)
    modal.open()
  }

  totalPrice = () => {
    return OrderItemsStore.getTotalPriceData()
  }

  bindEvents = () => {
    const shippo = $('[data-shippo]').data('shippo')

    this.$element.find('.shopping-cart--remove').on('click', (e) => {
      const index = $(e.currentTarget).closest('.item-index').data('index')
      this.removeItem(index)
    })

    this.$element.find('.shopping-cart--plus').on('click', (e) => {
      const index = $(e.currentTarget).closest('.item-index').data('index')
      this.increaseQuantity(index)
    })

    this.$element.find('.shopping-cart--minus').on('click', (e) => {
      const index = $(e.currentTarget).closest('.item-index').data('index')
      this.decreaseQuantity(index)
    })

    this.$element.find('.shopping-cart--checkout').on('click', this.submitOrder)

    this.$element.find('.shopping-cart--stripe-checkout').on('click', (e) => {
      if (shippo) {
        this.renderShippingDetails()
      } else {
        this.handleCheckoutClick($(e.currentTarget))
      }
    })

    this.$element.find('.shopping-cart-offer--checkout').on('click', this.offerCheckOut)
    this.$element.find('.shopping-cart--paypal-checkout').on('click', this.paypalCheckOut)
    this.$element.find('.shopping-cart--back').on('click', () => {
      this.$parent.closest('.modal').modal('hide')
    })
  }

  handleCheckoutClick = ($targetEl) => {
    const originalText = $targetEl.find('span').text()

    $targetEl.prop('disabled', true)
    $targetEl.find('span').text(I18n.t('processing'))

    this.hideErrorMessage()

    this.checkOut().catch((error) => {
      if (error.status === 422) {
        const errors = Object.entries(error.responseJSON['errors']).map(([_, values]) => values).join('<br>')
        console.error(errors)
        this.showErrorMessage(errors)
      } else {
        console.error(error)
        this.showErrorMessage(I18n.t('checkout_error'))
      }

      $targetEl.prop('disabled', false)
      $targetEl.find('span').text(originalText)
    }).then((checkoutUrl) => {
      if (checkoutUrl) {
        window.location.href = checkoutUrl
      }
    })
  }

  render = () => {
    OrderItemsStore.updateItemsAsync().then(() => {
      this.$parent.html(this.template).ready(() => {
        this.$element = this.$parent.children().first()
        this.bindEvents()
        this.$form = this.$element.find('form')
      })
    })
  }

  renderShippingDetails = () => {
    ShippingAddress.render(this.$parent, this.render, this.checkOut)
  }

  renderItemPrice = (item) => {
    if (currency && item.availablePrices) {
      const listingPrice = item.availablePrices.find(el => el.currency === currency.toUpperCase())
      if (listingPrice) {
        return listingPrice.display_price
      }
    }
    return 0
  }

  renderItemStatus = (item) => {
    if (item.listing_type === 'sold') {
      return `<span class="badge badge-warning">${I18n.t('sold')}</span>`
    } else if (!item.with_stock) {
      return `<span class="badge badge-warning">${I18n.t('out_of_stock')}</span>`
    }
    return ''
  }

  get template () {
    const items = OrderItemsStore.getItems()
    const paypalKey = $('[data-paypal-key]').data('paypal-key')
    const stripe = $('[data-stripe]').data('stripe')
    const showQuantity = !WebsiteConfigs.createWebshopLead && WebsiteConfigs.showListingQuantity
    const showPrice = !(WebsiteConfigs.privatePriceWebshop || WebsiteConfigs.createWebshopLead)

    let checkoutButtonKey = 'submit_order'
    let continueShoppingKey = 'continue_shopping'
    if (WebsiteConfigs.privatePriceWebshop || WebsiteConfigs.createWebshopLead) {
      checkoutButtonKey = 'submit_quote_request'
      continueShoppingKey = 'continue_browsing'
    } else if (WebsiteConfigs.allowOffer) {
      checkoutButtonKey = 'buy_it_now'
    } else if (paypalKey || stripe) {
      checkoutButtonKey = 'checkout'
    }
    let checkoutButtonTranslation = I18n.t(checkoutButtonKey)
    const continueShoppingTranslation = I18n.t(continueShoppingKey)

    if (WebsiteConfigs.submitOrderButtonText) {
      checkoutButtonTranslation = WebsiteConfigs.submitOrderButtonText
    }

    if (items.length > 0) {
      return `
        <div>
          <div class="d-none d-md-block">
            <div class="alert alert-danger" id="error_alert" style="display: none;">
              <div class="error-message"></div>
            </div>
            <div class="d-flex mb-2">
              <div class="d-flex" style="width: 350px">
                <div class="mr-3">#</div>
                <div>${I18n.t('title')}</div>
              </div>
              <div class="d-flex mb-1 text-sm-right justify-content-end w-100">
                ${showPrice ? `<div class="mr-3 text-left">${I18n.t('price')}</div>` : ''}
                ${showQuantity ? `<div class="mr-3 text-left" style="width: 110px">${I18n.t('quantity')}</div>` : ''}
                <div style="width: 30px"></div>
              </div>
            </div>
          </div>
          ${items.map((item, index) => `
            <div data-index="${index}" class="item-index d-flex flex-row flex-wrap flex-md-nowrap justify-content-between mb-2 align-items-start border-bottom">
              <div class="d-flex mb-1 tr" style="width: 350px;">
                <div class="mr-3 scope="row">${index + 1}</div>
                <div style="max-width: 350px;" class="item-title">
                  <a class="mr-2 text-dark font-weight-bold" href="/listings/${item.listing_id}">${item.title}</a>
                  ${this.renderItemStatus(item)}
                </div>
              </div>
              <div class="d-flex flex-md-nowrap flex-wrap mb-1 text-sm-right justify-content-end w-md-100 w-auto align-items-center">
                ${showPrice ? `<div class="text-right mb-2 mb-md-0">${this.renderItemPrice(item)}</div>` : ''}
                <div class="d-flex flex-nowrap ml-3">
                  ${showQuantity ? `
                    <div class="mr-3">
                      <div class="input-group" style="width: 110px;">
                        <div class="input-group-prepend">
                          <button class="btn btn-sm btn-dark shopping-cart--minus" ${item.quantity === 1 ? ' disabled' : ''}>
                            <i class="fa fa-minus"></i>
                          </button>
                        </div>
                        <div class="text-center item-quantity item-quantity">${item.quantity}</div>
                        <div class="input-group-append">
                          <button class="btn btn-sm btn-dark shopping-cart--plus" ${this.maxQuantityReached(item) ? ' disabled' : ''}>
                            <i class="fa fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ` : ''}
                  <div class="text-right mb-1">
                    <button class="btn btn-sm btn-danger shopping-cart--remove">
                      <i class="fa fa-times fa-solid"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>`).join('')}
          <div>
            ${showPrice ? `
              <div class="text-right">
                <h4>${I18n.t('total')}: ${this.totalPrice()}</h4>
                ${paypalKey ? `
                  <div class="mb-2 text-grey">
                    <i>* ${I18n.t('before_tax_and_shipping')}</i>
                  </div>
                ` : ''}
              </div>
            ` : ''}
            ${WebsiteConfigs.gdprCompliance ? `<div class="form-check mb-3">
              <label for="receive_newsletters">
                <input type="checkbox" name="receive_newsletters" id="receive_newsletters" value="" class="form-check-input">
                ${I18n.t('receive_newsletters')}
              </label>
            </div>` : ''}
            <button class="btn btn-secondary shopping-cart--back">
              <i class="fa fa-chevron-left"></i>
              ${continueShoppingTranslation}
            </button>
            <button class="btn btn-success shopping-cart--${paypalKey ? 'paypal-' : ''}${stripe ? 'stripe-' : ''}checkout checkout-button-margin">
              <i class="fa fa-shopping-cart"></i>
              <span>${checkoutButtonTranslation}</span>
            </button>
            ${WebsiteConfigs.allowOffer ? `
              <button class="btn btn-primary shopping-cart-offer--checkout checkout-button-margin">
                ${I18n.t('make_an_offer')}
              </button>` : ''}
          </div>
        </div>
      `
    } else if (this.isSuccess) {
      return `
        <div class="text-center">
          <h5>${I18n.t('thank_you_for_your_order')}</h5>
          <h5>${I18n.t('we_will_contact_shortly')}</h5>
        </div>
        `
    } else {
      return `<div class="text-center"><h3>${I18n.t('shopping_cart_is_empty')}</h3></div>`
    }
  }
}

export default ShoppingCart
