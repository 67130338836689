import OrderItemsStore from './order_items_store'
import ShoppingCart from './shopping_cart'
import DynamicModal from 'modals/scripts/dynamic_modal'
import Track from 'tracking_events/scripts/track'
import WebsiteConfigs from 'website_configs/website_configs'

const MODAL_SELECTOR = '#shopping_cart_modal'

class AddToCartButton {
  static async init () {
    $(document).on('click', '.js-add-to-cart-btn', (e) => {
      const $target = $(e.currentTarget)
      const listingId = $target.data('listing-id')

      OrderItemsStore.addItem({ listing_id: listingId })

      const modal = new DynamicModal(MODAL_SELECTOR, { size: 'lg', header_title: WebsiteConfigs.shoppingCartHeaderTitle })
      ShoppingCart.render(modal.$body)
      modal.open()

      new Track().send('event', {
        event_category: 'add_to_cart_click',
        listing_ids: [listingId]
      })
    })
  }
}

export default AddToCartButton
